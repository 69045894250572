import React, {
  useCallback, useEffect, useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { cancelRequest, useStores } from 'react-omnitech-api'
import StoreLocationsView from './store-locations-view'

function StoreLocationsController() {
  // prepare hook
  const { t } = useTranslation()
  const { fetchStores } = useStores()

  // internal state
  const [stores, setStores] = useState([])
  const [pageReady, setPageReady] = useState(false)

  // local variable
  const seoTitle = t('screens.storeLocations.seo.title')

  /**
   * fetchStoresApi
   * get stores data from API
   */
  const fetchStoresApi = useCallback(async () => {
    try {
      // api call option
      const option = {
        includes: [
          'address',
          'banner_images',
          'description',
        ].join(','),
      }
      // call api
      const { stores: data } = await fetchStores(option)
      setStores(data)
    } catch (error) {
      // TODO: when fail to load content group, what should be shown?
    } finally {
      setPageReady(true)
    }
  }, [fetchStores])

  /**
   * load article content group when page loaded
   */
  useEffect(() => {
    fetchStoresApi()

    return function fetchStoresApiCleanUp() {
      cancelRequest.cancelAll([
        'fetchStores',
      ])
    }
  }, [fetchStoresApi])

  const viewProps = {
    stores,
    pageReady,
    seoTitle,
  }

  return (
    <StoreLocationsView {...viewProps} />
  )
}

export default StoreLocationsController
