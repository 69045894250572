/* eslint-disable max-len */
/* eslint-disable no-cond-assign */
import _ from 'lodash'

const getStoreInfoFromDescription = (type, html) => {
  let regexp
  switch (type) {
    case 'phone':
      regexp = new RegExp('<a[^>]*href=[\'|"](?<url>tel:[^>|\'|"]+)[\'|"][^>]*>(?<content>.*?)<\\/a', 'g')
      break;
    case 'email':
      regexp = new RegExp('<a[^>]*href=[\'|"](?<url>mailto:[^>|\'|"]+)[\'|"][^>]*>(?<content>.*?)<\\/a', 'g')
      break;
    default:
      regexp = new RegExp(`<${type}[^>]*>(?<content>[\\s\\S]*?)<\\/${type}>`, 'gm')
      break;
  }
  let array
  let result = []
  while ((array = regexp.exec(html)) !== null) {
    result = _.concat(result, _.get(array, 'groups'))
  }
  return _.castArray(result.filter((v, i, a) => a.findIndex((t) => (t.url === v.url || t.content === v.content)) === i))
}

export default getStoreInfoFromDescription
