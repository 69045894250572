import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  container: {
    width: '100%',
    padding: [[`${theme.gap}rem`, '2rem']],
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      padding: [[`${theme.doubleGap}rem`, 0]],
    },
  },
}))

export default useStyles
