import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  container: {
    width: '100%',
    backgroundColor: theme.white,
    padding: [[`${theme.doubleGap}rem`, 0]],

  },
  content: {
    paddingTop: '1.5rem',
    '& p': {
      lineHeight: 1.43,
      fontSize: '1.4rem',
      color: theme.colors.text,
      margin: 0,
    },
  },
  link: {
    color: 'currentColor',
    textDecoration: 'none',
  },
  row: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  col30: {
    flex: '1 1 100%',
  },
  col70: {
    flex: '1 1 100%',
  },
  section: {
    marginBottom: `${theme.gap}rem`,
  },
  title: {
    textTransform: 'uppercase',
    marginTop: '3rem',
  },
  infoBox: {
    marginTop: '2rem',
    marginBottom: '2rem',
  },
  mapStyle: {
    width: '100%',
    height: 'auto',
    minHeight: '35rem',
    display: 'grid',
  },
  carouselLocation: {
    '& .slick-slide img': {
      width: 'inherit',
    },
  },
  [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
    container: {
      textAlign: 'left',
    },
    content: {
      paddingTop: '3rem',
    },
    row: {
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    col30: {
      flex: '1 1 30%',
    },
    col70: {
      flex: '1 1 70%',
    },
    mapStyle: {
      width: '100%',
      minWidth: '50rem',
      height: '50rem',
      minHeight: '35rem',
      display: 'block',
    },
  },
}))

export default useStyles
