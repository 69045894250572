/* eslint-disable react/no-danger */
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import useStyles from './store-location-card-style'
import { Carousel, Title, Col } from '../../../ui'
import getStoreInfoFromDescription from '../../../helpers/get-store-info-from-description'

const StoreLocationCardView = (props) => {
  const styles = useStyles()
  const { location } = props
  const { t } = useTranslation()
  const googleMapUrl = location.lat && location.long
    ? `https://maps.google.com/maps?q=${location.lat}%2C${location.long}&t=&z=18&ie=UTF8&iwloc=&output=embed`
    : ''

  // Handle Address from Description
  const fallbackAddress = _.concat(
    _.get(location, 'address.formattedAddressLines', []),
    _.get(location, 'address.country', ''),
  )
  const formattedPhone = _.get(location, 'phoneData.formatted')
  const locationPhone = _.get(location, 'phone')
  const fallbackPhone = locationPhone
    ? [{
      content: `${t('screens.stores.hotLine')}: ${formattedPhone}`,
      url: `tel:+${locationPhone.replace(/[^0-9]+/g, '')}`,
    }]
    : []
  const descriptionHtml = (_.get(location, 'description') || '').trim()
  const descriptionPhone = getStoreInfoFromDescription('phone', descriptionHtml)
  const descriptionAddressLines = _.map(getStoreInfoFromDescription('address', descriptionHtml), 'content')
  const openingHours = _.map(getStoreInfoFromDescription('time', descriptionHtml), 'content')
  const info = _.map(getStoreInfoFromDescription('article', descriptionHtml), 'content')
  const phone = _.isEmpty(descriptionPhone)
    ? fallbackPhone : getStoreInfoFromDescription('phone', descriptionHtml)
  const email = getStoreInfoFromDescription('email', descriptionHtml)
  const address = _.isEmpty(descriptionAddressLines)
    ? fallbackAddress : descriptionAddressLines
  const contacts = _.concat(phone, email)

  return (
    <div className={styles.container}>
      <Carousel
        images={location.bannerImages}
        slidesToShowDesktop={1}
        slidesToShowMovil={1}
        postionDots={30}
        postionDotsMovile={5}
        autoplay
        classNaneCarousel={styles.carouselLocation}
      />
      <div className={styles.content}>
        <Title text={location.name} />
        <div className={styles.row}>
          <Col className={styles.col70}>
            <section className={styles.section}>
              <h6 className={styles.title}>{t('screens.stores.location')}</h6>
              <div className={styles.infoBox}>
                {
                  address.map((line) => (
                    <p key={line} dangerouslySetInnerHTML={{ __html: line }} />
                  ))
                }
              </div>
              {
                !_.isEmpty(contacts) && (
                  <div className={styles.infoBox}>
                    {
                      _.map(contacts, ({ url, content }) => (
                        <p key={content}><a href={url} className={styles.link}>{content}</a></p>
                      ))
                    }
                  </div>
                )
              }
            </section>
            {
              !_.isEmpty(openingHours) && (
                <section className={styles.section}>
                  <h6 className={styles.title}>{t('screens.stores.hours')}</h6>
                  <div className={styles.infoBox}>
                    {
                      openingHours.map((line) => (
                        <p key={line} dangerouslySetInnerHTML={{ __html: line }} />
                      ))
                    }
                  </div>
                </section>
              )
            }
            {
              !_.isEmpty(info) && (
                <section className={styles.section}>
                  <div className={styles.infoBox}>
                    {
                      info.map((line) => (
                        <div key={line} dangerouslySetInnerHTML={{ __html: line }} />
                      ))
                    }
                  </div>
                </section>
              )
            }
          </Col>
          {
            googleMapUrl && (
              <Col className={styles.col30}>
                <div className={styles.mapStyle}>
                  <iframe
                    src={googleMapUrl}
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    allowFullScreen=""
                    aria-hidden="false"
                    title="store-map"
                  />
                </div>
              </Col>
            )
          }
        </div>
      </div>
    </div>
  )
}

StoreLocationCardView.propTypes = {
  location: PropTypes.object.isRequired,
}

export default StoreLocationCardView
